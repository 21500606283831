<template>
    <v-container fluid class="pa-4 pa-sm-6">
        <!-- Header Section -->
        <v-row class="mb-4">
            <v-col cols="12">
                <div class="d-flex flex-column flex-sm-row align-start align-sm-center">
                    <div class="mb-4 mb-sm-0">
                        <h2 class="text-h5 text-sm-h4 font-weight-medium mb-1">
                            Evaluaciones
                        </h2>
                        <span class="subtitle-2 text-sm-subtitle-1 text--secondary">
                            Gestiona las evaluaciones del usuario
                        </span>
                    </div>
                    <v-spacer></v-spacer>
                    <div class="d-flex w-100 w-sm-auto">
                        <v-btn color="success" class="flex-grow-1 flex-sm-grow-0 mr-2 rounded-lg" elevation="1" rounded
                            @click="showEvaluateNowDialog">
                            <v-icon left>mdi-clipboard-check</v-icon>
                            <span class="d-none d-sm-inline mr-1">Evaluar </span> Ahora
                        </v-btn>
                        <v-btn color="primary" class="flex-grow-1 flex-sm-grow-0 rounded-lg" elevation="1" rounded
                            @click="showScheduleDialog">
                            <v-icon left>mdi-calendar-plus</v-icon>
                            <span class="d-none d-sm-inline mr-1">Agendar </span> Evaluación
                        </v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>

        <v-divider class="mb-6" />

        <!-- Data Table Section -->
        <v-card elevation="2" class="rounded-lg">


            <v-data-table :headers="isMobile ? mobileHeaders : headers" :items="evaluations" :loading="loading"
                sort-by="date" sort-desc :footer-props="{
                    'items-per-page-options': [5, 10, 20],
                    'items-per-page-text': 'Registros por página'
                }" :mobile-breakpoint="0">
                <template v-slot:loading>
                    <v-skeleton-loader type="table-row-divider@3" class="pa-4"></v-skeleton-loader>
                </template>

                <template v-slot:item.date="{ item }">
                    <div class="font-weight-medium">
                        {{ formatDate(item.date) }}
                    </div>
                </template>

                <template v-slot:item.formName="{ item }">
                    <div class="d-flex align-center">
                        <v-icon small color="primary" class="mr-2">
                            mdi-file-document-outline
                        </v-icon>
                        <span class="font-weight-medium">{{ item.formName }}</span>
                    </div>
                </template>

                <template v-slot:item.status="{ item }">
                    <v-chip small :color="item.evaluatedBy ? 'success' : 'warning'" text-color="white"
                        class="font-weight-medium">
                        {{ item.evaluatedBy ? 'Completada' : 'Pendiente' }}
                    </v-chip>
                </template>

                <template v-slot:item.evaluatorName="{ item }">
                    <div class="d-flex align-center">
                        <v-icon small color="grey darken-2" class="mr-2">
                            mdi-account
                        </v-icon>
                        {{ item.evaluatorName || '—' }}
                    </div>
                </template>

                <template v-slot:item.actions="{ item }">
                    <template v-if="item.evaluatedBy">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn small fab elevation="2" color="info" @click="viewEvaluation(item)" class="mr-2"
                                    v-bind="attrs" v-on="on">
                                    <v-icon small>mdi-eye</v-icon>
                                </v-btn>
                            </template>
                            <span>Ver resultados</span>
                        </v-tooltip>
                    </template>
                    <template v-else>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn small fab elevation="2" color="success" @click="completePendingEvaluation(item)"
                                    class="mr-2" v-bind="attrs" v-on="on">
                                    <v-icon small>mdi-clipboard-edit</v-icon>
                                </v-btn>
                            </template>
                            <span>Completar evaluación</span>
                        </v-tooltip>
                    </template>
                </template>
            </v-data-table>
        </v-card>

        <!-- Evaluation Dialog -->
        <v-dialog v-model="dialogs.evaluation" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-btn icon dark @click="closeEvaluationDialog">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>
                        Formulario de Evaluación
                    </v-toolbar-title>
                </v-toolbar>

                <v-card-text class="pa-0">
                    <form-runner :formId="selectedEvaluation?.formId" :evaluationId="selectedEvaluation?.doc_id"
                        :formInput="formInput" />
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- Schedule Evaluation Dialog -->
        <v-dialog v-model="dialogs.schedule" max-width="500">
            <v-card>
                <v-toolbar dark color="primary" class="elevation-0">
                    <v-toolbar-title>Agendar Evaluación</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="dialogs.schedule = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-card-text class="pt-4">
                    <v-form ref="scheduleForm" v-model="formValid">
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-menu v-model="dateMenu" :close-on-content-click="false" transition="scale-transition"
                                    offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="scheduleData.date" label="Fecha" readonly v-bind="attrs"
                                            v-on="on" :rules="[v => !!v || 'Fecha requerida']" dense outlined>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker v-model="scheduleData.date" no-title scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateMenu = false">Cancelar</v-btn>
                                        <v-btn text color="primary"
                                            @click="$refs.dateMenu.save(scheduleData.date)">OK</v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>

                            <v-col cols="12" md="6">
                                <v-menu ref="timeMenu" v-model="timeMenu" :close-on-content-click="false"
                                    :return-value.sync="scheduleData.time" transition="scale-transition" offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="scheduleData.time" label="Hora" readonly v-bind="attrs"
                                            v-on="on" :rules="[v => !!v || 'Hora requerida']" dense outlined>
                                        </v-text-field>
                                    </template>
                                    <v-time-picker v-if="timeMenu" v-model="scheduleData.time" full-width format="24hr"
                                        @click:minute="$refs.timeMenu.save(scheduleData.time)">
                                    </v-time-picker>
                                </v-menu>
                            </v-col>

                            <v-col cols="12">
                                <v-select v-model="scheduleData.formId" :items="forms" item-text="title" item-value="id"
                                    label="Formulario" :rules="[v => !!v || 'Formulario requerido']"
                                    :loading="loadingForms" dense outlined>
                                </v-select>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="dialogs.schedule = false">Cancelar</v-btn>
                    <v-btn color="primary" :loading="saving" @click="scheduleEvaluation">Agendar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Evaluate Now Dialog -->
        <v-dialog v-model="dialogs.evaluateNow" max-width="500">
            <v-card>
                <v-toolbar dark color="success" class="elevation-0">
                    <v-toolbar-title>Evaluar Ahora</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="dialogs.evaluateNow = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-card-text class="pt-4">
                    <v-select v-model="selectedFormId" :items="forms" item-text="title" item-value="id"
                        label="Seleccionar Formulario" :rules="[v => !!v || 'Formulario requerido']"
                        :loading="loadingForms" dense outlined>
                    </v-select>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="dialogs.evaluateNow = false">Cancelar</v-btn>
                    <v-btn color="success" :disabled="!selectedFormId" @click="startEvaluation">
                        Comenzar Evaluación
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Direct Evaluation Dialog -->
        <v-dialog v-model="dialogs.directEvaluation" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar dark color="success">
                    <v-btn icon dark @click="closeDirectEvaluation">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Nueva Evaluación</v-toolbar-title>
                </v-toolbar>

                <v-card-text class="pa-0">
                    <form-runner v-if="selectedFormId" :formId="selectedFormId" :userId="user.id"
                        @submitted="handleDirectEvaluationSubmitted" />
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { getFirestore, collection, query, where, getDocs, getDoc, addDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { doc } from 'firebase/firestore';
import moment from 'moment';
import FormRunner from '@/components/forms/FormRunner.vue';

export default {
    name: 'UserEvaluations',
    components: {
        FormRunner
    },
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            loading: false,
            saving: false,
            loadingForms: false,
            forms: [],
            evaluations: [],
            headers: [
                {
                    text: 'Fecha',
                    value: 'date',
                    width: '200',
                    class: 'text--primary'
                },
                {
                    text: 'Formulario',
                    value: 'formName',
                    class: 'text--primary'
                },
                {
                    text: 'Estado',
                    value: 'status',
                    class: 'text--primary'
                },
                {
                    text: 'Evaluador',
                    value: 'evaluatorName',
                    class: 'text--primary'
                },
                {
                    text: '',
                    value: 'actions',
                    sortable: false,
                    width: '100',
                    align: 'center'
                }
            ],
            mobileHeaders: [
                {
                    text: 'Detalles',
                    value: 'formName',
                    class: 'text--primary'
                },
                {
                    text: '',
                    value: 'actions',
                    sortable: false,
                    align: 'end'
                }
            ],
            dialogs: {
                evaluation: false,
                schedule: false,
                evaluateNow: false,
                directEvaluation: false
            },
            selectedEvaluation: null,
            formInput: null,
            dateMenu: false,
            timeMenu: false,
            formValid: false,
            scheduleData: {
                date: null,
                time: null,
                formId: null
            },
            selectedFormId: null
        };
    },
    computed: {
        isMobile() {
            return this.$vuetify.breakpoint.xsOnly;
        }
    },
    methods: {
        formatDate(date) {
            if (!date) return '';
            return moment(date.seconds * 1000).format('DD/MM/YYYY HH:mm');
        },
        async loadEvaluations() {
            this.loading = true;
            try {
                const db = getFirestore();
                const evaluationsRef = collection(db, 'evaluations');
                const q = query(
                    evaluationsRef,
                    where('id', '==', this.user.id),
                    where('type', '==', 'form')
                );
                const querySnapshot = await getDocs(q);

                this.evaluations = await Promise.all(querySnapshot.docs.map(async docSnapshot => {
                    const data = { ...docSnapshot.data(), doc_id: docSnapshot.id };

                    // Get evaluator name if exists
                    if (data.evaluatorId) {
                        const docRef = doc(db, 'users', data.evaluatorId);
                        const evaluatorDoc = await getDoc(docRef);
                        if (evaluatorDoc.exists()) {
                            data.evaluatorName = evaluatorDoc.data().displayName;
                        }
                    }

                    // Get form name if exists
                    if (data.formId) {
                        const formRef = doc(db, 'forms', data.formId);
                        const formDoc = await getDoc(formRef);
                        if (formDoc.exists()) {
                            data.formName = formDoc.data().title;
                        } else {
                            data.formName = 'Formulario no encontrado';
                        }
                    }

                    return data;
                }));
            } catch (error) {
                console.error('Error loading evaluations:', error);
            } finally {
                this.loading = false;
            }
        },
        async viewEvaluation(evaluation) {
            this.selectedEvaluation = evaluation;

            if (evaluation.type === 'form' && evaluation.formInputId) {
                await this.getFormInputData(evaluation.formInputId);
            }

            this.dialogs.evaluation = true;
        },
        async getFormInputData(formInputId) {
            try {
                const db = getFirestore();
                const docRef = doc(db, 'forms_inputs', formInputId);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    this.formInput = docSnap.data();
                }
            } catch (error) {
                console.error('Error fetching form input:', error);
            }
        },
        closeEvaluationDialog() {
            this.dialogs.evaluation = false;
            this.selectedEvaluation = null;
            this.formInput = null;
        },
        async loadForms() {
            this.loadingForms = true;
            try {
                const db = getFirestore();
                const formsSnapshot = await getDocs(collection(db, 'forms'));
                this.forms = formsSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
            } catch (error) {
                console.error('Error loading forms:', error);
            } finally {
                this.loadingForms = false;
            }
        },
        showScheduleDialog() {
            this.scheduleData = {
                date: moment().format('YYYY-MM-DD'),
                time: moment().format('HH:mm'),
                formId: null
            };
            this.dialogs.schedule = true;
        },
        async scheduleEvaluation() {
            if (!this.$refs.scheduleForm.validate()) return;

            this.saving = true;
            try {
                const db = getFirestore();
                const evaluationData = {
                    date: new Date(`${this.scheduleData.date} ${this.scheduleData.time}`),
                    id: this.user.id,
                    type: 'form',
                    formId: this.scheduleData.formId,
                    evaluatorId: this.$store.state.Auth.token.claims.user_id,
                    createdAt: serverTimestamp()
                };

                await addDoc(collection(db, 'evaluations'), evaluationData);
                this.loadEvaluations(); // Refresh the list
                this.dialogs.schedule = false;

                this.$notify({
                    group: 'feedback',
                    type: 'success',
                    title: 'Éxito',
                    text: 'Evaluación agendada correctamente'
                });
            } catch (error) {
                console.error('Error scheduling evaluation:', error);
                this.$notify({
                    group: 'feedback',
                    type: 'error',
                    title: 'Error',
                    text: 'Error al agendar la evaluación'
                });
            } finally {
                this.saving = false;
            }
        },
        showEvaluateNowDialog() {
            this.selectedFormId = null;
            this.dialogs.evaluateNow = true;
        },
        startEvaluation() {
            this.dialogs.evaluateNow = false;
            this.dialogs.directEvaluation = true;
        },
        closeDirectEvaluation() {
            this.dialogs.directEvaluation = false;
            this.selectedFormId = null;
            this.selectedEvaluation = null;
        },
        completePendingEvaluation(evaluation) {
            this.selectedEvaluation = evaluation;
            this.selectedFormId = evaluation.formId;
            this.dialogs.directEvaluation = true;
        },
        async handleDirectEvaluationSubmitted(data) {
            try {
                const db = getFirestore();

                if (this.selectedEvaluation?.doc_id) {
                    // Update existing evaluation
                    const docRef = doc(db, 'evaluations', this.selectedEvaluation.doc_id);
                    await updateDoc(docRef, {
                        formInputId: data.formInputId,
                        evaluatorId: this.$store.state.Auth.token.claims.user_id,
                        evaluatedBy: data.evaluatorId,
                        evaluatedDate: data.date
                    });
                } else {
                    // Create new evaluation (existing code)
                    const evaluationData = {
                        date: new Date(),
                        id: this.user.id,
                        type: 'form',
                        formId: this.selectedFormId,
                        formInputId: data.formInputId,
                        evaluatorId: this.$store.state.Auth.token.claims.user_id,
                        evaluatedBy: data.evaluatorId,
                        evaluatedDate: data.date,
                        createdAt: serverTimestamp()
                    };
                    await addDoc(collection(db, 'evaluations'), evaluationData);
                }

                this.loadEvaluations(); // Refresh the list
                this.closeDirectEvaluation();

                this.$notify({
                    group: 'feedback',
                    type: 'success',
                    title: 'Éxito',
                    text: 'Evaluación completada correctamente'
                });
            } catch (error) {
                console.error('Error saving evaluation:', error);
                this.$notify({
                    group: 'feedback',
                    type: 'error',
                    title: 'Error',
                    text: 'Error al guardar la evaluación'
                });
            }
        }
    },
    created() {
        this.loadEvaluations();
        this.loadForms();
    }
};
</script>

<style scoped>
.v-data-table ::v-deep .v-data-table-header th {
    font-weight: 600 !important;
    text-transform: uppercase;
    font-size: 0.875rem;
}

.v-data-table ::v-deep tbody tr {
    transition: background-color 0.2s ease-in-out;
}

.v-btn.v-btn--fab.v-size--small {
    height: 32px;
    width: 32px;
}

.v-chip {
    font-size: 0.8125rem !important;
}

.v-data-table ::v-deep .v-data-table__wrapper {
    border-radius: 0 0 8px 8px;
}

/* Theme-aware styles */
.v-card {
    border: 1px solid var(--v-border-base);
}



.theme--light .v-card {
    background-color: #ffffff;
}

.theme--dark .v-data-table ::v-deep tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.05) !important;
}

.theme--light .v-data-table ::v-deep tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.03) !important;
}

.w-100 {
    width: 100%;
}

.w-sm-auto {
    @media (min-width: 600px) {
        width: auto !important;
    }
}

/* Mobile optimizations */
.v-data-table ::v-deep .v-data-table__wrapper {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.v-data-table ::v-deep tbody td {
    height: auto !important;
    padding: 12px 16px !important;
}

@media (max-width: 599px) {
    .v-data-table ::v-deep .v-data-table__wrapper td {
        padding: 8px !important;
    }

    .v-data-table ::v-deep .v-chip {
        margin: 4px 0;
    }

    .v-btn.v-btn--fab.v-size--small {
        height: 36px;
        width: 36px;
    }

    .v-btn.v-btn--fab.v-size--small .v-icon {
        font-size: 20px;
    }
}

/* Improve dialog responsiveness */
.v-dialog.v-dialog--fullscreen ::v-deep .v-toolbar {
    padding: 0 8px;
}

.v-dialog:not(.v-dialog--fullscreen) ::v-deep .v-card {
    margin: 12px;
    width: calc(100% - 24px);
}
</style>