<template>
    <v-container>
        <!-- Loading State -->
        <v-skeleton-loader v-if="loading" type="article" />

        <!-- No Data State -->
        <v-alert v-else-if="!latestEvaluation" type="info" text>
            No se encontraron evaluaciones antropométricas para este usuario.
        </v-alert>

        <template v-else>
            <!-- Latest Evaluation Card -->
            <v-card class="mb-4">
                <v-card-title class="d-flex align-center">
                    <span>Última Evaluación ISAK-{{ latestEvaluation.isakLevel }}</span>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="generatePDF">
                        <v-icon left>mdi-file-pdf</v-icon>
                        Exportar PDF
                    </v-btn>
                </v-card-title>

                <v-card-subtitle>
                    {{ formatDate(latestEvaluation.date) }}
                </v-card-subtitle>

                <v-divider></v-divider>

                <!-- Photos Gallery -->
                <v-row class="pa-4" v-if="hasPhotos">
                    <v-col cols="12" sm="4" v-for="(url, view) in latestEvaluation.data.photos" :key="view">
                        <v-card outlined>
                            <v-img :src="url" height="200" contain class="grey lighten-2" :alt="`Vista ${view}`">
                                <template v-slot:placeholder>
                                    <v-row class="fill-height ma-0" align="center" justify="center">
                                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                                    </v-row>
                                </template>
                            </v-img>
                            <v-card-subtitle class="text-center">
                                {{ getPhotoViewLabel(view) }}
                            </v-card-subtitle>
                        </v-card>
                    </v-col>
                </v-row>

                <!-- Basic Measurements -->
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="6">
                            <h3 class="text-h6 mb-4">Medidas Básicas</h3>
                            <v-card outlined>
                                <v-simple-table>
                                    <tbody>
                                        <tr v-for="(value, key) in filteredBasicMeasurements" :key="key">
                                            <td class="font-weight-medium">{{ getMeasurementLabel(key) }}:</td>
                                            <td class="text-right">
                                                {{ value ? formatMeasurement(value, getMeasurementUnit(key)) : '-' }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                            </v-card>
                        </v-col>

                        <v-col cols="12" md="6">
                            <h3 class="text-h6 mb-4">Índices Calculados</h3>
                            <v-card outlined>
                                <v-simple-table>
                                    <tbody>
                                        <tr>
                                            <td class="font-weight-medium">Masa Corporal:</td>
                                            <td class="text-right">
                                                {{ calculateBMI().toFixed(1) || '-' }} kg/m²
                                                <div class="caption">{{ getBMICategory() || '-' }}</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-medium">Masa Grasa Libre:</td>
                                            <td class="text-right">
                                                {{ calculateFFMI().toFixed(1) || '-' }} kg/m²
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-medium">Ratio Cintura-Cadera:</td>
                                            <td class="text-right">
                                                {{ calculateWaistHipRatio().toFixed(2) || '-' }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-medium">Ratio Cintura-Altura:</td>
                                            <td class="text-right">
                                                {{ calculateWaistHeightRatio().toFixed(2) || '-' }}

                                                <v-tooltip bottom v-if="calculateWaistHeightRatio() > 0.5">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon small color="red" v-bind="attrs"
                                                            v-on="on">mdi-alert</v-icon>
                                                    </template>
                                                    <span>El valor de la cintura es mayor que la altura</span>
                                                </v-tooltip>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-medium">Ratio Altura-Peso:</td>
                                            <td class="text-right">
                                                {{ calculateHeightWeightRatio().toFixed(2) || '-' }}
                                            </td>
                                        </tr>

                                        <!-- Custom calculations from profile -->
                                        <tr v-for="calc in profileCalculations" :key="calc.name">
                                            <td class="font-weight-medium">{{ calc.name }}:</td>
                                            <td class="text-right">
                                                {{ evaluateFormula(calc.formula).toFixed(1) || '-' }} {{ calc.unit }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>

                                <!-- Keep the legend -->
                                <!-- <v-card-text class="caption text-center grey--text">
                                    <div>IMC: Índice de Masa Corporal</div>
                                    <div>FFMI: Fat Free Mass Index</div>
                                    <div>ICC: Índice Cintura-Cadera</div>
                                    <div>ICT: Índice Cintura-Talla</div>
                                </v-card-text> -->
                            </v-card>
                        </v-col>
                    </v-row>

                    <!-- Body Composition Section -->
                    <v-row class="mt-4">
                        <v-col cols="12" md="6">
                            <h3 class="text-h6 mb-4">Composición Corporal</h3>
                            <v-card outlined>
                                <v-simple-table>
                                    <tbody>
                                        <tr>
                                            <td class="font-weight-medium">Grasa Corporal:</td>
                                            <td class="text-right">{{ calculateBodyFatPercentage().toFixed(1) }}%</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-medium">Masa Muscular:</td>
                                            <td class="text-right">{{ calculateMuscleMass().toFixed(1) }}%</td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-medium">Peso Graso:</td>
                                            <td class="text-right">
                                                {{ (basicMeasurements.bodyWeight * calculateBodyFatPercentage() /
                                                    100).toFixed(1) }} kg
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-medium">Peso Muscular:</td>
                                            <td class="text-right">
                                                {{ (basicMeasurements.bodyWeight * calculateMuscleMass() /
                                                    100).toFixed(1) }} kg
                                            </td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                            </v-card>
                        </v-col>

                        <v-col cols="12" md="6">
                            <h3 class="text-h6 mb-4">Somatotipo <span class="caption">(Heath-Carter)</span></h3>
                            <v-card outlined>
                                <v-card-text>
                                    <somatotype-chart :endomorphy="calculateSomatotype().endomorphy"
                                        :mesomorphy="calculateSomatotype().mesomorphy"
                                        :ectomorphy="calculateSomatotype().ectomorphy" />
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <!-- Historical Data -->
            <v-card v-if="evaluations.length > 1">
                <v-card-title>Histórico de Evaluaciones</v-card-title>
                <v-card-text>
                    <v-tabs v-model="activeTab">
                        <v-tab>Peso</v-tab>
                        <v-tab>Pliegues (Suma)</v-tab>
                        <v-tab>Pliegues (Individual)</v-tab>
                        <v-tab>Perímetros</v-tab>
                        <v-tab>Somatotipo</v-tab>
                        <v-tab>Índices</v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="activeTab">
                        <v-tab-item>
                            <trend-chart :data="weightTrendData" label="Peso Corporal" unit="kg" />
                        </v-tab-item>
                        <v-tab-item>
                            <trend-chart :data="skinfoldsTrendData" label="Suma de Pliegues" unit="mm" />
                        </v-tab-item>
                        <v-tab-item>
                            <div>
                                <v-alert v-if="individualSkinfoldsWarnings.length > 0" type="warning" text class="mb-4">
                                    Hay valores faltantes en las siguientes fechas: {{
                                        individualSkinfoldsWarnings.join(', ') }}
                                </v-alert>
                                <trend-chart :data="individualSkinfoldsTrendData" label="Pliegues Cutáneos" unit="mm"
                                    :multipleLines="true" :lineLabels="{
                                        triceps: 'Tríceps',
                                        subscapular: 'Subescapular',
                                        biceps: 'Bíceps',
                                        iliacCrest: 'Cresta Ilíaca',
                                        supraspinale: 'Supraespinal',
                                        abdominal: 'Abdominal',
                                        frontThigh: 'Muslo Frontal',
                                        medialCalf: 'Pantorrilla Medial'
                                    }" />
                            </div>
                        </v-tab-item>
                        <v-tab-item>
                            <trend-chart :data="girthsTrendData" label="Perímetros Principales" unit="cm" />
                        </v-tab-item>
                        <v-tab-item>
                            <div>
                                <v-alert v-if="somatotypeWarnings.length > 0" type="warning" text class="mb-4">
                                    Hay valores faltantes en las siguientes fechas: {{ somatotypeWarnings.join(', ') }}
                                </v-alert>
                                <trend-chart :data="somatotypeTrendData" label="Componentes del Somatotipo"
                                    :multipleLines="true" unit="" :lineLabels="{
                                        endomorphy: 'Endomorfia',
                                        mesomorphy: 'Mesomorfia',
                                        ectomorphy: 'Ectomorfia'
                                    }" />
                            </div>
                        </v-tab-item>
                        <v-tab-item>
                            <div>
                                <trend-chart :data="indicesTrendData" label="Índices Antropométricos"
                                    :multipleLines="true" unit="" :lineLabels="{
                                        bmi: 'IMC',
                                        ffmi: 'FFMI',
                                        whr: 'Ratio Cintura-Cadera',
                                        wht: 'Ratio Cintura-Altura'
                                    }" />
                            </div>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card-text>
            </v-card>
        </template>
    </v-container>
</template>

<script>
import { getFirestore, collection, query, where, orderBy, getDocs, getDoc, doc } from 'firebase/firestore'
import SomatotypeChart from './charts/SomatotypeChart.vue'
import TrendChart from './charts/TrendChart.vue'
/* import { jsPDF } from 'jspdf'
 */

import moment from 'moment';


export default {
    name: 'IsakAnthropometricSummary',

    components: {
        SomatotypeChart,
        TrendChart
    },

    props: {
        user: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            loading: true,
            evaluations: [],
            latestEvaluation: null,
            activeTab: 0,
            somatotypeWarningsSet: new Set(),
            individualSkinfoldsWarningsSet: new Set(),
            profile: null,
        }
    },

    async created() {
        await this.fetchEvaluations()
        if (this.latestEvaluation) {
            await this.loadProfile()
        }
    },

    methods: {
        async fetchEvaluations() {
            try {
                const db = getFirestore()
                const q = query(
                    collection(db, 'anthropometry'),
                    where('user_id', '==', this.$props.user.id),
                    orderBy('date', 'desc')
                )

                const querySnapshot = await getDocs(q)
                this.evaluations = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }))

                this.latestEvaluation = this.evaluations[0]
                this.loading = false
            } catch (error) {
                console.error('Error fetching evaluations:', error)
                this.loading = false
            }
        },

        formatDate(date) {
            return new Date(date.seconds * 1000).toLocaleDateString()
        },

        getPhotoViewLabel(view) {
            const labels = {
                front: 'Vista Frontal',
                back: 'Vista Posterior',
                side: 'Vista Lateral'
            }
            return labels[view] || view
        },

        getMeasurementLabel(key) {
            const labels = {
                bodyWeight: 'Peso Corporal',
                standingHeight: 'Estatura',
                sittingHeight: 'Altura Sentado',
                armSpan: 'Envergadura de Brazos'
                // Add more labels as needed
            }
            return labels[key] || key
        },

        getMeasurementUnit(key) {
            const units = {
                bodyWeight: 'kg',
                standingHeight: 'cm',
                sittingHeight: 'cm',
                armSpan: 'cm'
                // Add more units as needed
            }
            return units[key] || ''
        },

        formatMeasurement(value, unit) {
            return `${value} ${unit}`
        },

        calculateBMI() {
            const weight = this.basicMeasurements.bodyWeight
            const height = this.basicMeasurements.standingHeight / 100 // convert to meters
            return weight / (height * height)
        },

        getBMICategory() {
            const bmi = this.calculateBMI()
            if (bmi < 18.5) return 'Bajo peso'
            if (bmi < 25) return 'Normal'
            if (bmi < 30) return 'Sobrepeso'
            return 'Obesidad'
        },

        calculateHeightWeightRatio() {
            return this.basicMeasurements.standingHeight / this.basicMeasurements.bodyWeight
        },

        calculateBodyFatPercentage() {
            // Implement body fat calculation Jackson-Pollock formula
            const age = this.$props.user.birthDate ? moment().diff(moment(this.$props.user.birthDate, 'YYYY-MM-DD'), 'years') : 35;
            const fatPercentage = this.calculateBodyFatPercentageIsak(this.latestEvaluation.data.skinfolds, this.$props.user.gender, age)
            return fatPercentage
        },

        calculateBodyFatPercentageIsak(skinfolds, gender, age) {
            // Get the sum of skinfolds (only the skinfold measurements provided)
            // skinflod values are strings, so we need to parse them to numbers

            let sumSkinfolds = 0
            for (const key in skinfolds) {
                if (skinfolds.hasOwnProperty(key)) {
                    sumSkinfolds += parseFloat(skinfolds[key]) || 0
                }
            }

            // Check if gender is provided ('male' or 'female')
            if (!gender || (gender !== 'male' && gender !== 'female')) {
                return 'invalid gender'
            }

            // ISAK formula for males
            if (gender === 'male') {
                const bodyFatPercentage = 0.29288 * sumSkinfolds - 0.0005 * Math.pow(sumSkinfolds, 2) + 0.15845 * age - 5.76377;
                return bodyFatPercentage;
            }

            // ISAK formula for females
            if (gender === 'female') {
                const bodyFatPercentage = 0.29669 * sumSkinfolds - 0.00043 * Math.pow(sumSkinfolds, 2) + 0.02963 * age + 1.4072;
                return bodyFatPercentage;
            }
        },

        calculateJacksonPollock(skinfolds, age) {

            if (this.$props.user.gender == 'male') {
                let skinfoldsSum = parseFloat(skinfolds.chest || 0) + parseFloat(skinfolds.abdominal || 0) + parseFloat(skinfolds.frontThigh || 0)
                let bodyDensity = 1.10938
                    - (0.0008267 * skinfoldsSum)
                    + (0.0000016 * Math.pow(skinfoldsSum, 2))
                    - (0.0002574 * age);

                let bodyFatPercentage = (495 / bodyDensity) - 450

                return bodyFatPercentage
            }

            if (this.$props.user.gender == 'female') {
                let skinfoldsSum = parseFloat(skinfolds.triceps || 0) + parseFloat(skinfolds.suprailiac || 0) + parseFloat(skinfolds.frontThigh || 0)
                let bodyDensity = 1.0994921
                    - (0.0009929 * skinfoldsSum)
                    + (0.0000023 * Math.pow(skinfoldsSum, 2))
                    - (0.0001392 * age);

                let bodyFatPercentage = (495 / bodyDensity) - 450

                return bodyFatPercentage
            }

        },

        calculateFMandFFM() {
            let bodyFatPercentage = this.calculateBodyFatPercentage()
            let fatMass = this.basicMeasurements.bodyWeight * (bodyFatPercentage / 100)
            let fatFreeMass = this.basicMeasurements.bodyWeight - fatMass
            return {
                fatMass: fatMass,
                fatFreeMass: fatFreeMass
            }
        },

        calculateWaistHipRatio() {
            let data = this.latestEvaluation.data

            debugger;
            let waist = parseFloat(data.girths.waist)
            let hip = parseFloat(data.girths.hips)
            return waist / hip
        },

        calculateWaistHeightRatio() {
            let data = this.latestEvaluation.data
            let waist = parseFloat(data.girths.waist)
            let height = parseFloat(data.basicMeasurements.standingHeight)
            return waist / height
        },

        calculateFFMI() {
            let data = this.latestEvaluation.data
            let fatFreeMass = this.calculateFMandFFM().fatFreeMass
            let height = parseFloat(data.basicMeasurements.standingHeight) / 100
            return fatFreeMass / Math.pow(height, 2)
        },

        calculateLeanBodyMass() {
            let data = this.latestEvaluation.data
            let bodyFatPercentage = this.calculateBodyFatPercentage()
            let leanBodyMass = data.basicMeasurements.bodyWeight * (1 - bodyFatPercentage / 100)
            return leanBodyMass
        },

        calculateMuscleMass() {
            let bodyFatPercentage = this.calculateBodyFatPercentage()
            let muscleMassPercentage = 100 - bodyFatPercentage
            return muscleMassPercentage
        },

        calculateSomatotype() {
            try {
                const measurements = this.latestEvaluation.data;

                // Parse inputs
                const height = parseFloat(measurements.basicMeasurements.standingHeight); // cm
                const weight = parseFloat(measurements.basicMeasurements.bodyWeight); // kg
                const humerus = parseFloat(measurements.breadths.humerus); // cm
                const femur = parseFloat(measurements.breadths.femur); // cm
                const relaxedArm = parseFloat(measurements.girths.relaxedArm); // cm
                const calf = parseFloat(measurements.girths.maximumCalf); // cm
                const calf_skinfold = parseFloat(measurements.skinfolds.maximalCalf); // mm
                const triceps = parseFloat(measurements.skinfolds.triceps); // mm
                const subscapular = parseFloat(measurements.skinfolds.subscapular); // mm
                const supraspinale = parseFloat(measurements.skinfolds.supraspinale); // mm

                // 1. Endomorphy
                const sumSkinfolds =
                    (triceps + subscapular + supraspinale) * 170.17 / height;
                let endomorphy = sumSkinfolds > 170 ? 0.5 :
                    -0.7182 + (0.1451 * sumSkinfolds) - (0.00068 * Math.pow(sumSkinfolds, 2)) + (0.0000014 * Math.pow(sumSkinfolds, 3));

                // mesomorphy excel =(0.858*humerus)+(0.601*femur)+(0.188*(relaxedArm-(triceps*3.141/10)))+(0.161*( calf - (calf_skinfold * 3.141 / 10)))-(height*0.131)+4.5



                // 2. Mesomorphy
                let mesomorphy = (0.858 * humerus)
                    + (0.601 * femur)
                    + (0.188 * (relaxedArm - (triceps * 3.141 / 10)))
                    + (0.161 * (calf - (calf_skinfold * 3.141 / 10)))
                    - (height * 0.131) + 4.5
                // 3. Ectomorphy

                // calculate height to weight ratio
                const HWR = height / Math.pow(weight, 0.3333);
                const ectomorphy = HWR >= 40.75 ? 0.732 * HWR - 28.58 : 0.463 * HWR - 17.63;

                return {
                    endomorphy: parseFloat(endomorphy.toFixed(1)),
                    mesomorphy: parseFloat(mesomorphy.toFixed(1)),
                    ectomorphy: parseFloat(ectomorphy.toFixed(1))
                };
            } catch (error) {
                console.error('Error calculating somatotype:', error);
                return { endomorphy: 0, mesomorphy: 0, ectomorphy: 0 };
            }
        },

        async generatePDF() {
            const doc = new jsPDF()
            // Implement PDF generation logic
            doc.save('anthropometric-evaluation.pdf')
        },

        calculateSumOfSkinfolds(skinfolds) {
            return Object.values(skinfolds).reduce((sum, value) => sum + parseFloat(value || 0), 0)
        },

        calculateSomatotypeForEvaluation(evaluation) {
            try {
                const measurements = evaluation.data;

                const height = parseFloat(measurements.basicMeasurements.standingHeight);
                const weight = parseFloat(measurements.basicMeasurements.bodyWeight);
                const humerus = parseFloat(measurements.breadths.humerus);
                const femur = parseFloat(measurements.breadths.femur);
                const relaxedArm = parseFloat(measurements.girths.relaxedArm);
                const calf = parseFloat(measurements.girths.maximumCalf);
                const calf_skinfold = parseFloat(measurements.skinfolds.maximalCalf);
                const triceps = parseFloat(measurements.skinfolds.triceps);
                const subscapular = parseFloat(measurements.skinfolds.subscapular);
                const supraspinale = parseFloat(measurements.skinfolds.supraspinale);

                const sumSkinfolds = (triceps + subscapular + supraspinale) * 170.17 / height;
                const endomorphy = sumSkinfolds > 170 ? 0.5 :
                    -0.7182 + (0.1451 * sumSkinfolds) - (0.00068 * Math.pow(sumSkinfolds, 2)) + (0.0000014 * Math.pow(sumSkinfolds, 3));

                const mesomorphy = (0.858 * humerus)
                    + (0.601 * femur)
                    + (0.188 * (relaxedArm - (triceps * 3.141 / 10)))
                    + (0.161 * (calf - (calf_skinfold * 3.141 / 10)))
                    - (height * 0.131) + 4.5;

                const HWR = height / Math.pow(weight, 0.3333);
                const ectomorphy = HWR >= 40.75 ? 0.732 * HWR - 28.58 : 0.463 * HWR - 17.63;

                return {
                    endomorphy: parseFloat(endomorphy.toFixed(1)),
                    mesomorphy: parseFloat(mesomorphy.toFixed(1)),
                    ectomorphy: parseFloat(ectomorphy.toFixed(1))
                };
            } catch (error) {
                console.error('Error calculating somatotype:', error);
                return { endomorphy: 0, mesomorphy: 0, ectomorphy: 0 };
            }
        },

        evaluateFormula(formula) {
            debugger;
            try {
                // Create context with available measurements
                const context = {
                    bodyWeight: parseFloat(this.basicMeasurements.bodyWeight),
                    standingHeight: parseFloat(this.basicMeasurements.standingHeight),
                    // Add skinfolds
                    skinfolds: {
                        triceps: parseFloat(this.latestEvaluation.data.skinfolds?.triceps) || 0,
                        subscapular: parseFloat(this.latestEvaluation.data.skinfolds?.subscapular) || 0,
                        biceps: parseFloat(this.latestEvaluation.data.skinfolds?.biceps) || 0,
                    },
                    // Add girths

                    waist: parseFloat(this.latestEvaluation.data.girths?.waist) || 0,
                    hip: parseFloat(this.latestEvaluation.data.girths?.hip) || 0,
                    chest: parseFloat(this.latestEvaluation.data.girths?.chest) || 0,
                    // Add more measurements as needed
                };

                // Create function with context variables
                const fn = new Function(...Object.keys(context), `return ${formula}`);
                return fn(...Object.values(context));
            } catch (error) {
                console.error('Error evaluating formula:', error);
                return 0;
            }
        },

        async loadProfile() {
            if (!this.latestEvaluation?.profileId) return;

            try {
                const db = getFirestore();
                const profileDoc = await getDoc(doc(db, 'measurement_profiles', this.latestEvaluation.profileId));
                if (profileDoc.exists()) {
                    this.profile = profileDoc.data();
                }
            } catch (error) {
                console.error('Error loading profile:', error);
            }
        }
    },

    computed: {
        hasPhotos() {
            return this.latestEvaluation?.data?.photos &&
                Object.keys(this.latestEvaluation.data.photos).length > 0
        },

        basicMeasurements() {
            return this.latestEvaluation?.data?.basicMeasurements || {}
        },

        weightTrendData() {
            return this.evaluations.map(evaluation => ({
                date: new Date(evaluation.date.seconds * 1000),
                value: evaluation.data.basicMeasurements.bodyWeight
            })).reverse()
        },

        skinfoldsTrendData() {
            return this.evaluations.map(evaluation => ({
                date: new Date(evaluation.date.seconds * 1000),
                value: this.calculateSumOfSkinfolds(evaluation.data.skinfolds)
            })).reverse()
        },

        girthsTrendData() {
            return this.evaluations.map(evaluation => ({
                date: new Date(evaluation.date.seconds * 1000),
                values: {
                    waist: evaluation.data.girths.waist,
                    hip: evaluation.data.girths.hip,
                    flexedArm: evaluation.data.girths.flexedArm
                }
            })).reverse()
        },

        filteredBasicMeasurements() {
            return Object.entries(this.basicMeasurements)
                .filter(([_, value]) => value)
                .reduce((acc, [key, value]) => {
                    acc[key] = value;
                    return acc;
                }, {});
        },

        somatotypeTrendData() {
            const warnings = new Set();
            const data = this.evaluations.map(evaluation => {
                const date = new Date(evaluation.date.seconds * 1000);
                const somatotype = this.calculateSomatotypeForEvaluation(evaluation);

                // Filter out NaN values and collect warnings
                const values = {};
                for (const [key, value] of Object.entries(somatotype)) {
                    if (!isNaN(value)) {
                        values[key] = value;
                    } else {
                        warnings.add(date.toLocaleDateString());
                    }
                }

                return {
                    date,
                    values
                };
            }).reverse();

            // Store warnings in a separate computed property
            this.somatotypeWarningsSet = warnings;
            return data;
        },

        somatotypeWarnings() {
            return Array.from(this.somatotypeWarningsSet || new Set());
        },

        individualSkinfoldsTrendData() {
            const warnings = new Set();
            const data = this.evaluations.map(evaluation => {
                const date = new Date(evaluation.date.seconds * 1000);
                const skinfolds = evaluation.data.skinfolds;

                // Filter out undefined or NaN values
                const values = {};
                for (const [key, value] of Object.entries(skinfolds)) {
                    const parsedValue = parseFloat(value);
                    if (!isNaN(parsedValue)) {
                        values[key] = parsedValue;
                    } else if (value !== undefined) {
                        warnings.add(date.toLocaleDateString() + ' - ' + key + 'Falta valor');
                    }
                }

                return {
                    date,
                    values
                };
            }).reverse();

            // Store warnings in a separate computed property
            this.individualSkinfoldsWarningsSet = warnings;
            return data;
        },

        individualSkinfoldsWarnings() {
            return Array.from(this.individualSkinfoldsWarningsSet || new Set());
        },

        indicesTrendData() {
            return this.evaluations.map(evaluation => {
                const date = new Date(evaluation.date.seconds * 1000);
                const data = evaluation.data;

                // Calculate indices for this evaluation
                const height = data.basicMeasurements.standingHeight / 100; // convert to meters
                const weight = data.basicMeasurements.bodyWeight;
                const waist = data.girths?.waist;
                const hip = data.girths?.hip;

                // Calculate body fat percentage for this evaluation
                const age = moment().diff(moment(this.$props.user.birthDate, 'YYYY-MM-DD'), 'years');
                const bodyFatPercentage = this.calculateBodyFatPercentageIsak(data.skinfolds, this.$props.user.gender, age);
                const fatFreeMass = weight * (1 - bodyFatPercentage / 100);

                const values = {};

                // BMI
                if (!isNaN(height) && !isNaN(weight)) {
                    values.bmi = weight / (height * height);
                }

                // FFMI
                if (!isNaN(height) && !isNaN(fatFreeMass)) {
                    values.ffmi = fatFreeMass / (height * height);
                }

                // Waist-Hip Ratio
                if (!isNaN(waist) && !isNaN(hip) && hip !== 0) {
                    values.whr = waist / hip;
                }

                // Waist-Height Ratio
                if (!isNaN(waist) && !isNaN(height) && height !== 0) {
                    values.wht = waist / (height * 100); // height back to cm for this ratio
                }

                return {
                    date,
                    values
                };
            }).reverse();
        },

        customCalculations() {
            return {
                // Standard calculations
                bmi: {
                    name: 'Índice de Masa Corporal',
                    calculate: () => this.calculateBMI(),
                    unit: 'kg/m²',
                    category: this.getBMICategory()
                },
                bodyFat: {
                    name: 'Grasa Corporal',
                    calculate: () => this.calculateBodyFatPercentage(),
                    unit: '%'
                },
                ffmi: {
                    name: 'Masa Grasa Libre',
                    calculate: () => this.calculateFFMI(),
                    unit: 'kg/m²'
                },
                whr: {
                    name: 'Ratio Cintura-Cadera',
                    calculate: () => this.calculateWaistHipRatio(),
                    unit: ''
                },
                wht: {
                    name: 'Ratio Cintura-Altura',
                    calculate: () => this.calculateWaistHeightRatio(),
                    unit: '',
                    warning: (value) => value > 0.5 ? 'El valor de la cintura es mayor que la altura' : null
                },
                // Add custom calculation methods that professionals can define
                ...this.professionalCustomCalculations
            }
        },

        // Allow professionals to define their own calculations
        professionalCustomCalculations() {
            // This could be loaded from their profile or a database
            return this.profile?.customCalculations || {}
        },

        profileCalculations() {
            return this.profile?.calculations || [];
        }
    }
}
</script>

<style scoped>
.v-card {
    transition: transform 0.2s, box-shadow 0.2s;
}

.v-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
}

.text-h6 {
    color: var(--v-primary-darken1);
    font-weight: 600;
}

.v-list-item {
    min-height: 40px;
}

.v-progress-circular {
    margin: 0 auto;
    display: block;
}
</style>
<style>
@media print {
    .v-btn {
        display: none !important;
    }

    .v-navigation-drawer {
        display: none !important;
    }



    .v-toolbar {
        display: none !important;
    }
}
</style>