<template>
    <v-container fluid class="pa-4 pa-sm-6">
        <!-- Header Section -->
        <v-row class="mb-4">
            <v-col cols="12">
                <div class="d-flex flex-column flex-sm-row align-start align-sm-center">
                    <div class="mb-4 mb-sm-0">
                        <h2 class="text-h5 text-sm-h4 font-weight-medium mb-1">
                            Evaluaciones Antropométricas
                        </h2>
                        <span class="subtitle-2 text-sm-subtitle-1 text--secondary">
                            Gestiona las evaluaciones antropométricas del usuario
                        </span>
                    </div>
                    <v-spacer></v-spacer>
                    <div class="d-flex w-100 w-sm-auto">
                        <v-btn color="success" class="flex-grow-1 flex-sm-grow-0 mr-2 rounded-lg" elevation="1" rounded
                            @click="showEvaluateNowDialog">
                            <v-icon left>mdi-human-male-height</v-icon>
                            <span class="d-none d-sm-inline mr-1">Evaluar </span> Ahora
                        </v-btn>
                        <v-btn color="primary" class="flex-grow-1 flex-sm-grow-0 rounded-lg" elevation="1" rounded
                            @click="showScheduleDialog">
                            <v-icon left>mdi-calendar-plus</v-icon>
                            <span class="d-none d-sm-inline mr-1">Agendar </span> Evaluación
                        </v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>

        <v-divider class="mb-6" />

        <!-- Data Table Section -->
        <v-card elevation="2" class="rounded-lg">
            <v-data-table :headers="isMobile ? mobileHeaders : headers" :items="evaluations" :loading="loading"
                sort-by="date" sort-desc :footer-props="{
                    'items-per-page-options': [5, 10, 20],
                    'items-per-page-text': 'Registros por página'
                }" :mobile-breakpoint="0">
                <template #loading>
                    <v-skeleton-loader type="table-row-divider@3" class="pa-4"></v-skeleton-loader>
                </template>

                <template #[`item.date`]="{ item }">
                    <div class="font-weight-medium">
                        {{ formatDate(item.date) }}
                    </div>
                </template>

                <template #[`item.isakLevel`]="{ item }">
                    <div class="d-flex align-center">
                        <v-icon small color="primary" class="mr-2">
                            mdi-human-male-height
                        </v-icon>
                        <span class="font-weight-medium">ISAK-{{ item.isakLevel }}</span>
                    </div>
                </template>

                <template #[`item.status`]="{ item }">
                    <v-chip small :color="item.evaluatedBy ? 'success' : 'warning'" text-color="white"
                        class="font-weight-medium">
                        {{ item.evaluatedBy ? 'Completada' : 'Pendiente' }}
                    </v-chip>
                </template>

                <template #[`item.evaluatorName`]="{ item }">
                    <div class="d-flex align-center">
                        <v-icon small color="grey darken-2" class="mr-2">
                            mdi-account
                        </v-icon>
                        {{ item.evaluatorName || '—' }}
                    </div>
                </template>

                <template #[`item.actions`]="{ item }">
                    <template v-if="item.evaluatedBy">
                        <v-tooltip bottom>
                            <template #activator="{ on, attrs }">
                                <v-btn small fab elevation="2" color="info" @click="viewEvaluation(item)" class="mr-2"
                                    v-bind="attrs" v-on="on">
                                    <v-icon small>mdi-eye</v-icon>
                                </v-btn>
                            </template>
                            <span>Ver resultados</span>
                        </v-tooltip>
                    </template>
                    <template v-else>
                        <v-tooltip bottom>
                            <template #activator="{ on, attrs }">
                                <v-btn small fab elevation="2" color="success" @click="completePendingEvaluation(item)"
                                    class="mr-2" v-bind="attrs" v-on="on">
                                    <v-icon small>mdi-clipboard-edit</v-icon>
                                </v-btn>
                            </template>
                            <span>Completar evaluación</span>
                        </v-tooltip>
                    </template>
                </template>
            </v-data-table>
        </v-card>

        <!-- Schedule Dialog -->
        <v-dialog v-model="dialogs.schedule" max-width="500">
            <v-card>
                <v-toolbar dark color="primary" class="elevation-0">
                    <v-toolbar-title>Agendar Evaluación Antropométrica</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="dialogs.schedule = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-card-text class="pt-4">
                    <v-form ref="scheduleForm" v-model="formValid">
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-menu v-model="dateMenu" :close-on-content-click="false" transition="scale-transition"
                                    offset-y min-width="290px">
                                    <template #activator="{ on, attrs }">
                                        <v-text-field v-model="scheduleData.date" label="Fecha" readonly v-bind="attrs"
                                            v-on="on" :rules="[v => !!v || 'Fecha requerida']" dense outlined>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker v-model="scheduleData.date" no-title scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateMenu = false">Cancelar</v-btn>
                                        <v-btn text color="primary"
                                            @click="$refs.dateMenu.save(scheduleData.date)">OK</v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>

                            <v-col cols="12" md="6">
                                <v-menu ref="timeMenu" v-model="timeMenu" :close-on-content-click="false"
                                    :return-value.sync="scheduleData.time" transition="scale-transition" offset-y>
                                    <template #activator="{ on, attrs }">
                                        <v-text-field v-model="scheduleData.time" label="Hora" readonly v-bind="attrs"
                                            v-on="on" :rules="[v => !!v || 'Hora requerida']" dense outlined>
                                        </v-text-field>
                                    </template>
                                    <v-time-picker v-if="timeMenu" v-model="scheduleData.time" full-width format="24hr"
                                        @click:minute="$refs.timeMenu.save(scheduleData.time)">
                                    </v-time-picker>
                                </v-menu>
                            </v-col>

                            <v-col cols="12">
                                <v-select v-model="scheduleData.isakLevel" :items="isakLevels" label="Nivel ISAK"
                                    :rules="[v => !!v || 'Nivel ISAK requerido']" dense outlined>
                                </v-select>
                            </v-col>

                            <v-col cols="12">
                                <v-select v-model="scheduleData.evaluatorId" :items="evaluators" item-text="displayName"
                                    item-value="id" label="Evaluador" :rules="[v => !!v || 'Evaluador requerido']"
                                    :loading="loadingEvaluators" dense outlined>
                                </v-select>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="dialogs.schedule = false">Cancelar</v-btn>
                    <v-btn color="primary" :loading="saving" @click="scheduleEvaluation">Agendar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Evaluation Dialog -->
        <v-dialog v-model="dialogs.evaluation" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-btn icon dark @click="closeEvaluationDialog">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>
                        Evaluación Antropométrica
                    </v-toolbar-title>
                </v-toolbar>

                <v-card-text class="pa-0">
                    <isak-anthropometric-form :evaluationId="selectedEvaluation?.doc_id" :userId="user.id"
                        :isakLevel="selectedEvaluation?.isakLevel || 1"
                        :formInput="selectedEvaluation?.evaluatedBy ? anthropometryInput : null"
                        @submitted="handleAnthropometrySubmitted" @close="closeEvaluationDialog" />
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { getFirestore, collection, query, where, getDocs, getDoc, addDoc, updateDoc, doc } from 'firebase/firestore';
import moment from 'moment';
import IsakAnthropometricForm from '@/components/forms/IsakAnthropometricForm.vue';

export default {
    name: 'UserAnthropometryEvaluations',
    components: {
        IsakAnthropometricForm
    },
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            loading: false,
            saving: false,
            loadingEvaluators: false,
            evaluations: [],
            headers: [
                {
                    text: 'Fecha',
                    value: 'date',
                    width: '200',
                    class: 'text--primary'
                },
                {
                    text: 'Nivel ISAK',
                    value: 'isakLevel',
                    class: 'text--primary'
                },
                {
                    text: 'Estado',
                    value: 'status',
                    class: 'text--primary'
                },
                {
                    text: 'Evaluador',
                    value: 'evaluatorName',
                    class: 'text--primary'
                },
                {
                    text: '',
                    value: 'actions',
                    sortable: false,
                    width: '100',
                    align: 'center'
                }
            ],
            mobileHeaders: [
                {
                    text: 'Detalles',
                    value: 'isakLevel',
                    class: 'text--primary'
                },
                {
                    text: '',
                    value: 'actions',
                    sortable: false,
                    align: 'end'
                }
            ],
            dialogs: {
                evaluation: false,
                schedule: false,
            },
            selectedEvaluation: null,
            anthropometryInput: null,
            dateMenu: false,
            timeMenu: false,
            formValid: false,
            scheduleData: {
                date: null,
                time: null,
                isakLevel: 1,
                evaluatorId: null,
            },
            evaluators: [],
            isakLevels: [
                { text: 'Nivel 1', value: 1 },
                { text: 'Nivel 2', value: 2 },
            ],
        };
    },
    computed: {
        isMobile() {
            return this.$vuetify.breakpoint.xsOnly;
        }
    },
    methods: {
        formatDate(date) {
            if (!date) return '';
            return moment(date.seconds * 1000).format('DD/MM/YYYY HH:mm');
        },
        async loadEvaluations() {
            this.loading = true;
            try {
                const db = getFirestore();
                const evaluationsRef = collection(db, 'evaluations');
                const q = query(
                    evaluationsRef,
                    where('id', '==', this.user.id),
                    where('type', '==', 'anthropometric')
                );
                const querySnapshot = await getDocs(q);

                this.evaluations = await Promise.all(querySnapshot.docs.map(async docSnapshot => {
                    const data = { ...docSnapshot.data(), doc_id: docSnapshot.id };

                    if (data.evaluatorId) {
                        const docRef = doc(db, 'users', data.evaluatorId);
                        const evaluatorDoc = await getDoc(docRef);
                        if (evaluatorDoc.exists()) {
                            data.evaluatorName = evaluatorDoc.data().displayName;
                        }
                    }

                    return data;
                }));
            } catch (error) {
                console.error('Error loading evaluations:', error);
            } finally {
                this.loading = false;
            }
        },
        async loadEvaluators() {
            this.loadingEvaluators = true;
            try {
                const db = getFirestore();
                const evaluatorsQuery = query(
                    collection(db, 'users'),
                    where('type', 'in', ['admin', 'evaluator'])
                );
                const evaluatorsSnapshot = await getDocs(evaluatorsQuery);
                this.evaluators = evaluatorsSnapshot.docs.map(doc => ({
                    ...doc.data(),
                    id: doc.id,
                }));
            } catch (error) {
                console.error('Error loading evaluators:', error);
            } finally {
                this.loadingEvaluators = false;
            }
        },
        showScheduleDialog() {
            this.scheduleData = {
                date: moment().format('YYYY-MM-DD'),
                time: moment().format('HH:mm'),
                isakLevel: 1,
                evaluatorId: null
            };
            this.dialogs.schedule = true;
        },
        async scheduleEvaluation() {
            if (!this.$refs.scheduleForm.validate()) return;

            this.saving = true;
            try {
                const db = getFirestore();
                const evaluationData = {
                    date: new Date(`${this.scheduleData.date} ${this.scheduleData.time}`),
                    id: this.user.id,
                    type: 'anthropometric',
                    isakLevel: this.scheduleData.isakLevel,
                    evaluatorId: this.scheduleData.evaluatorId,
                };

                await addDoc(collection(db, 'evaluations'), evaluationData);
                this.loadEvaluations();
                this.dialogs.schedule = false;

                this.$notify({
                    group: 'feedback',
                    type: 'success',
                    title: 'Éxito',
                    text: 'Evaluación agendada correctamente'
                });
            } catch (error) {
                console.error('Error scheduling evaluation:', error);
                this.$notify({
                    group: 'feedback',
                    type: 'error',
                    title: 'Error',
                    text: 'Error al agendar la evaluación'
                });
            } finally {
                this.saving = false;
            }
        },
        showEvaluateNowDialog() {
            this.selectedEvaluation = {
                isakLevel: 1,
                type: 'anthropometric'
            };
            this.dialogs.evaluation = true;
        },
        async viewEvaluation(evaluation) {
            this.selectedEvaluation = evaluation;

            if (evaluation.anthropometryId) {
                await this.getAnthropometryData(evaluation.anthropometryId);
            }

            this.dialogs.evaluation = true;
        },
        async getAnthropometryData(anthropometryId) {
            try {
                const db = getFirestore();
                const docRef = doc(db, 'anthropometry', anthropometryId);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    this.anthropometryInput = {
                        ...docSnap.data(),
                        id: docSnap.id
                    };
                }
            } catch (error) {
                console.error('Error fetching anthropometry data:', error);
            }
        },
        closeEvaluationDialog() {
            this.dialogs.evaluation = false;
            this.selectedEvaluation = null;
            this.anthropometryInput = null;
        },
        completePendingEvaluation(evaluation) {
            this.selectedEvaluation = evaluation;
            this.dialogs.evaluation = true;
        },
        async handleAnthropometrySubmitted({ anthropometryId, evaluatorId, date }) {
            try {
                const db = getFirestore();
                await updateDoc(doc(db, 'evaluations', this.selectedEvaluation.doc_id), {
                    evaluatedBy: evaluatorId,
                    evaluatedDate: date,
                    anthropometryId: anthropometryId
                });

                this.loadEvaluations();
                this.closeEvaluationDialog();

                this.$notify({
                    group: 'feedback',
                    type: 'success',
                    title: 'Éxito',
                    text: 'Evaluación completada correctamente'
                });
            } catch (error) {
                console.error('Error updating evaluation:', error);
                this.$notify({
                    group: 'feedback',
                    type: 'error',
                    title: 'Error',
                    text: 'Error al actualizar la evaluación'
                });
            }
        }
    },
    created() {
        this.loadEvaluations();
        this.loadEvaluators();
    }
};
</script>

<style scoped>
.v-data-table ::v-deep .v-data-table-header th {
    font-weight: 600 !important;
    text-transform: uppercase;
    font-size: 0.875rem;
}

.v-data-table ::v-deep tbody tr {
    transition: background-color 0.2s ease-in-out;
}

.v-btn.v-btn--fab.v-size--small {
    height: 32px;
    width: 32px;
}

.v-chip {
    font-size: 0.8125rem !important;
}

.v-data-table ::v-deep .v-data-table__wrapper {
    border-radius: 0 0 8px 8px;
}

.w-100 {
    width: 100%;
}

.w-sm-auto {
    @media (min-width: 600px) {
        width: auto !important;
    }
}
</style>