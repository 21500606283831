<template>
    <v-card class="ma-4 glass mx-auto rounded-lg overflow-hidden" max-width="700">
        <v-card-title>
            <v-icon left>mdi-calendar-week</v-icon>
            Días de Entrenamiento
            <v-spacer></v-spacer>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on" class="ml-2" color="primary">
                        mdi-help-circle
                    </v-icon>
                </template>
                <span>
                    Selecciona los días fijos de entrenamiento para el cliente
                </span>
            </v-tooltip>
        </v-card-title>
        <v-divider />

        <v-card-text>
            <v-row>
                <v-col cols="12" sm="6" md="4" v-for="(day, index) in trainingDays" :key="index">
                    <v-checkbox v-model="day.selected" :label="day.name" hide-details class="mb-2"
                        @change="updateDays"></v-checkbox>
                </v-col>
            </v-row>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="saveTrainingDays" elevation="0" :loading="loading" color="success">
                <v-icon left>mdi-content-save</v-icon>
                Guardar
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { getFirestore, updateDoc, doc } from 'firebase/firestore'
import { logAuditEvent } from '@/error/audit.js'

export default {
    name: 'TrainingDays',
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            loading: false,
            trainingDays: [
                { name: 'Lunes', selected: false },
                { name: 'Martes', selected: false },
                { name: 'Miércoles', selected: false },
                { name: 'Jueves', selected: false },
                { name: 'Viernes', selected: false },
                { name: 'Sábado', selected: false },
                { name: 'Domingo', selected: false }
            ]
        }
    },
    methods: {
        updateDays() {
            this.$emit('update:training-days', this.getSelectedDays())
        },
        getSelectedDays() {
            return this.trainingDays
                .filter(day => day.selected)
                .map(day => day.name)
        },
        async saveTrainingDays() {
            try {
                this.loading = true
                const db = getFirestore()
                const userRef = doc(db, 'users', this.user.id)

                await updateDoc(userRef, {
                    trainingDays: this.getSelectedDays()
                })

                this.$notify({
                    type: 'success',
                    title: 'Éxito',
                    text: 'Días de entrenamiento actualizados correctamente.'
                })

                logAuditEvent('update', this.$store.state.Auth.token.claims.user_id,
                    `Training days updated for user ${this.user.id}`)
            } catch (e) {
                logAuditEvent('error', this.$store.state.Auth.token.claims.user_id,
                    `Error updating training days: ${e.message}`)
                this.$notify({
                    type: 'error',
                    title: 'Error',
                    text: 'Error al actualizar los días de entrenamiento.'
                })
            } finally {
                this.loading = false
            }
        }
    },
    created() {
        // Initialize selected days from user data if available
        if (this.user.trainingDays) {
            this.trainingDays.forEach(day => {
                day.selected = this.user.trainingDays.includes(day.name)
            })
        }
    }
}
</script>